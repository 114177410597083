var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "header",
    {
      staticClass: "header d-flex align-items-center fixed-top",
      class: { home: _vm.isHome, "sticky-top": !_vm.isHome },
      attrs: { id: "header" },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "container-fluid container-xl position-relative d-flex align-items-center",
        },
        [
          _c(
            "router-link",
            {
              staticClass: "logo d-flex align-items-center me-auto",
              attrs: { to: "/" },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../../content/images/logo.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("h1", { staticClass: "sitename" }, [
                _vm._v("TRƯỜNG THCS&THPT"),
                _c("br"),
                _vm._v("LƯƠNG THẾ VINH"),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("nav", { staticClass: "navmenu", attrs: { id: "navmenu" } }, [
            _c(
              "ul",
              [
                _vm._l(_vm.menus, function (menu, index) {
                  return [
                    menu.children && menu.children.length > 0
                      ? _c(
                          "li",
                          { class: `dropdown ${menu.class}` },
                          [
                            menu.href
                              ? _c(
                                  "router-link",
                                  { attrs: { to: menu.href } },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(menu.name) +
                                        " "
                                    ),
                                    _c("i", {
                                      staticClass:
                                        "bi bi-chevron-down toggle-dropdown",
                                    }),
                                  ]
                                )
                              : _c(
                                  "a",
                                  { attrs: { href: "javascript:void(0)" } },
                                  [
                                    _vm._v(" " + _vm._s(menu.name) + " "),
                                    _c("i", {
                                      staticClass:
                                        "bi bi-chevron-down toggle-dropdown",
                                    }),
                                  ]
                                ),
                            _vm._v(" "),
                            _c(
                              "ul",
                              { staticClass: "sub-menu" },
                              _vm._l(menu.children, function (child) {
                                return _c(
                                  "li",
                                  [
                                    _c(
                                      "router-link",
                                      { attrs: { to: child.href } },
                                      [_vm._v(_vm._s(child.name))]
                                    ),
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                          ],
                          1
                        )
                      : _c(
                          "li",
                          { class: `${menu.class}` },
                          [
                            _c("router-link", { attrs: { to: menu.href } }, [
                              _vm._v(_vm._s(menu.name)),
                            ]),
                          ],
                          1
                        ),
                  ]
                }),
                _vm._v(" "),
                _vm._m(0),
              ],
              2
            ),
            _vm._v(" "),
            _c("i", { staticClass: "mobile-nav-toggle d-xl-none bi bi-list" }),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("li", [
      _c("a", { attrs: { href: "https://dev-admin.vannhucuong.com.vn" } }, [
        _c("i", { staticClass: "fa-solid fa-seedling pr-2" }),
        _vm._v(" Sổ liên lạc điện tử"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }